@import '/src/app/scss/mixins';
@import '/src/app/scss/variables';
@import "../node_modules/intl-tel-input/build/css/intlTelInput.min.css";
@import "/src/assets/css/bootstrap-form.min.css";

body {
  background: $cream-light;
  font-family: $font-family-regular;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-family-sans-serif: 'Montserrat', sans-serif;

  &.fixed {
    overflow-y: hidden;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,
strong, b, .bold, .medium, .strong, .text-bold, .text-strong {
  font-family: $font-family-bold;
  font-weight: 500;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

*, ::after, ::before {
  box-sizing: inherit;
}

.btn-group {
  .btn + .btn {
    border-left: 1px solid #999;
  }
}

.f-row {
  display: flex;
  flex-direction: row;
}
