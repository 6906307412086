/**
  OnClass UIKit
  Тирографика
**/

@mixin title($title: 'h2') {
  color: $black-light;
  font-family: $font-family-bold;
  font-weight: inherit;
  margin: 0;

  @if ($title == 'h1') {
    font-size: 30px;
    line-height: 36px;
  } @else if ($title == 'h2') {
    font-size: 24px;
    line-height: 30px;
  } @else if ($title == 'h3') {
    font-size: 22px;
    line-height: 26px;
  } @else if ($title == 'h4') {
    font-size: 18px;
    line-height: 24px;
  } @else if ($title == 'h5') {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin text($text: 'base') {
  color: $black-light;
  font-family: $font-family-regular;
  font-weight: inherit;
  margin: 0;

  @if ($text == 'huge') {
    font-size: 20px;
    line-height: 28px;
  } @else if ($text == 'large') {
    font-size: 18px;
    line-height: 26px;
  } @else if ($text == 'base') {
    font-size: 16px;
    line-height: 22px;
  } @else if ($text == 'second') {
    font-size: 14px;
    line-height: 18px;
  } @else if ($text == 'second-bold') {
    font-family: $font-family-bold;
    font-size: 14px;
    line-height: 18px;
  } @else if ($text == 'explanatory') {
    font-size: 12px;
    line-height: 16px;
  } @else if ($text == 'explanatory-bold') {
    font-family: $font-family-bold;
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin link($link: 'base') {
  color: $sea-blue;
  font-family: $font-family-regular;
  font-weight: inherit;
  text-decoration: none;
  user-select: none;
  margin: 0;

  @if ($link == 'large') {
    font-size: 16px;
    line-height: 20px;
  } @else if ($link == 'base') {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin label($label: 'small') {
  color: $black-light;
  font-family: $font-family-regular;
  font-weight: inherit;

  @if ($label == 'big') {
    font-size: 22px;
    line-height: 22px;
  } @else if ($label == 'small') {
    font-size: 10px;
    line-height: 12px;
  }
}

@mixin ellipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-break: break-word;
  line-break: normal;
}
