$font-family-base: 'Montserrat', sans-serif;
$font-family-light: 'MontserratLight', sans-serif;
$font-family-regular: $font-family-base;
$font-family-medium: 'MontserratMedium', sans-serif;
$font-family-semi-bold: 'MontserratSemiBold', sans-serif;
$font-family-bold: 'MontserratBold', sans-serif;

$shadow-card: 0 0 10px #0000001A;
$transition-time: 600ms;
$transition-time-mini: 300ms;

/**
  OnClass UIKit
  Палитра цветов
**/

$white: #FFFFFF;
$black: #19262F;
$black-dark: #000000;
$black-light: #464646;

$gray: #CCCCCC;
$gray-light: #EEEEEE;
$gray-dark: #A4B0C3;

$pink: #FF3366;
$pink-light: #FFB8C9;
$pink-medium: #FFDCDC;
$green: #44DD94;
$cream-dark: #C4B2B2;
$cream-light: #FDF8F7;
$sea-blue: #00BAC6;
$violet: #53387F;
$violet-light: #CABED3;

$tomato: #E86F79;
$tomato-hover: #C84550;